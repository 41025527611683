<template>
  <BaseSearchForm>
    <template #SearchConditions>
      <SingleRowCondition>
        <template #Text>
          <label for="SKU">貨號：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="SKU"
                 v-model="SKU"
                 name="SKU"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="DESCR">貨品名稱：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="DESCR"
                 v-model="DESCR"
                 name="DESCR"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="LOTTABLE07">版本/批號：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="LOTTABLE07"
                 v-model="LOTTABLE07"
                 name="LOTTABLE07"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>上架日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleShipStartDateChange"
                      :date="shipDate.startDate"
                      :max="shipDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleShipEndDateChange"
                      :date="shipDate.endDate"
                      :min="shipDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="NOTES1">備註：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="NOTES1"
                 v-model="NOTES1"
                 name="NOTES1"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <div class="form__row">
        <div class="form__col form__col--head">
          <button class="form__btn form__btn-primary btn_search"
                  type="button"
                  @click="transData"> 搜尋</button>
          <button class="form__btn form__btn-default btn_clear"
                  type="button"
                  @click="clearData"> 清空 </button>
        </div>
      </div>
    </template>
  </BaseSearchForm>
</template>

<script>
import SearchFormMixin from '@/mixins/SearchFormMixin.js'
import BaseSearchForm from '@/components/Form/BaseSearchForm.vue';
import SingleRowCondition from '@/components/Form/SingleRowCondition';


export default {
  name: 'InventoryShelfDayForm',
  mixins: [SearchFormMixin],
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  data() {
    return {
      NOTES1:'',
      LOTTABLE07:'',
      DESCR:'',
      SKU:'',
      shipDate: {
        startDate: '',
        endDate: '',
      },
    };
  },
  created() {
  },
  computed: {},
  mounted() {
  },
  methods: {
    transData() {
      if(
        this.NOTES1 == '' &&
        this.LOTTABLE07 == '' &&
        this.DESCR == '' &&
        this.SKU == '' &&
        this.shipDate.startDate == '' &&
        this.shipDate.endDate == ''
      ){
        alert("搜尋條件不得為空，請輸入搜尋條件後重試。");
      } else {
        this.$emit('updateConditions', {
          NOTES1: this.NOTES1,
          LOTTABLE07: this.LOTTABLE07,
          DESCR: this.DESCR,
          SKU: this.SKU,
          shipDate: this.shipDate,
        });
      };
    },
    clearData(){
      this.NOTES1='';
      this.LOTTABLE07='';
      this.DESCR='';
      this.SKU='';
      this.shipDate.startDate='';
      this.shipDate.endDate= '';
    },
    handleShipStartDateChange(value) {
      this.shipDate.startDate = value;
    },
    handleShipEndDateChange(value) {
      this.shipDate.endDate = value;
    },
    handleOrderStartDateChange(value) {
      this.orderDate.startDate = value;
    },
    handleOrderEndDateChange(value) {
      this.orderDate.endDate = value;
    },
    handleDeliveryStartDateChange(value) {
      this.deliveryDate.startDate = value;
    },
    handleDeliveryEndDateChange(value) {
      this.deliveryDate.endDate = value;
    },
    handleOrderTypeChange(value) {
      this.orderType = value;
    },
    handleOrderStatusChange(value) {
      this.orderStatus = value;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
