<template>
  <div>
    <!-- <button @click='CallLoginRefreshCode'>click</button> -->
    <div class="s-canvas">
      <img width='160' height='50' id="verify-image" class="verify-image" @click='CallLoginRefreshCode' :src="verifyCode" type="image/png" alt="Verify">
    </div>
  </div>
</template>
<script>
  export default{
    name: 'Sidentify',
    props: ['verifyCode'],
    data() {
      return {
      };
    },
    methods: {
        CallLoginRefreshCode() {
          this.$emit('refreshCode')
        },
    },
  }
</script>
<style scoped lang="scss">
</style>