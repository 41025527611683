<template>
  <div class="form__row">
      <div class="form__col form__col--head">
        <slot name="Text">[Slot Text Default]</slot>
      </div>
      <div class="form__col form__col--body">
        <slot name="Content">[Slot Content Default]</slot>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SingleRowCondition',
}
</script>


<style scoped lang="scss">
.form__row {
  display: flex;
}

.form__row {
  margin-bottom: 20px;
}

.form__col--head {
  width: 10%;
}

.form__col--body {
  width: 90%;
}
</style>