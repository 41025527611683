<template>
  <div class="chooser">
    <div class="choose-columns-features" id="choose-columns-features">
      <h3>可選欄位</h3>
      <div id="chooser-opts" class="chooser-opts">
        <label id="Chooser-all-columns-label" class="Chooser-all-columns-label" for="Chooser-all-columns">全選</label>
        <input type="checkbox" id="Chooser-all-columns" class="Chooser-all-columns" v-model="chooser_all_columns" @change="chooser_chkbox_all_change">
      </div>
    </div>
    <div class="row">
      <div class="itemContainer">
        <Tag class="item"
             v-for="col in filterSelectCol" :key="col.id" :text="col.name"
             @tag-click="addColumns(col)"/>
      </div>
    </div>
    <h3>已選欄位</h3>
    <div class="row">
      <draggable class="itemContainer"
                 :list="this.selectColumns" :component-data="setDraggableData()">
        <Tag class="item"  v-for="selectCol in selectColumns"
             :key="selectCol.id"
             :text="selectCol.name"
             @tag-click="removeColumn(selectCol)"/>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Tag from '@/components/Tag/Tag.vue';

export default {
  components: {
    draggable,
    Tag,
  },
  name: 'Chooser.vue',
  props: ['columns'],
  data() {
    return {
      selectColumns: [],
      chooser_all_columns: true,
    };
  },
  computed: {
    filterSelectCol() {
      return this.columns.filter((item) => this.selectColumns.indexOf(item) < 0);
    },
  },
  methods: {
    addColumns(col) {
      // console.log(this.selectColumns);
      this.chooser_all_columns = false;
      this.selectColumns.push(col);
      this.$emit('updateSelectColumns', this.selectColumns);
    },
    removeColumn(col) {
      // console.log(this.selectColumns);
      this.selectColumns.splice(this.selectColumns.indexOf(col), 1);
      this.$emit('updateSelectColumns', this.selectColumns);
    },
    moveColumn() {
      // console.log(this.selectColumns);
      this.$emit('updateSelectColumns', this.selectColumns);
    },
    setDraggableData() {
      return {
        on: {
          change: this.moveColumn,
          // input: this.inputChanged
        },
        attrs: {
          wrap: true,
        },
        props: {
          // value: this.activeNames,
        },
      };
    },
    chooser_chkbox_all_change(){
      if(this.chooser_all_columns == true){
        this.selectColumns = [];
        // console.log(this.selectColumns);
      };
      this.$emit('updateSelectColumns', this.selectColumns);
    },
  },
};
</script>

<style scoped>
.row {
  min-height: 40px;
  width: 100%;
  overflow:auto;
}
.itemContainer{
  display:flex;
}
.item {
  width:fit-content;
  height: fit-content;
  margin-right: 10px;
  cursor:pointer;
  white-space: nowrap;
}

#Chooser-all-columns{
  width: 18px;
  height: 18px;
}

#choose-columns-features{
  display: flex;
}

#Chooser-all-columns-label{
  font-size:18px;
}

#chooser-opts{
  padding-top: 24px;
  padding-left: 16px;
}
</style>
