<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/purchase" name="進貨資料查詢" icon="ti-import"/>
        <sidebar-link to="/goods_change" name="貨品進出異動" icon="ti-exchange-vertical"/>
        <sidebar-link to="/inventory" name="貨品庫存查詢" icon="ti-package"/>
        <sidebar-link to="/inventory_shelfday" name="貨品庫存查詢(上架日)" icon="ti-package"/>
        <sidebar-link to="/shipping" name="出貨資料查詢" icon="ti-truck"/>
        <sidebar-link to="/order_status" name="訂單狀態查詢" icon="ti-stats-up"/>
        <sidebar-link v-if="permission===0||permission===1" to="/user" name="使用者管理" icon="ti-user"/>
        <sidebar-link to="/change_password" name="修改密碼" icon="ti-lock"/>
        <!-- <sidebar-link to="/stats" name="用戶設定" icon="ti-user"/> -->
        <!-- <sidebar-link to="/dashboard" name="Dashboard" icon="ti-panel"/> -->
        <!-- <sidebar-link to="/typography" name="Typography" icon="ti-text"/>
        <sidebar-link to="/icons" name="Icons" icon="ti-pencil-alt2"/> -->
        <!-- <sidebar-link to="/maps" name="Map" icon="ti-map"/> -->
        <!-- <sidebar-link to="/notifications" name="Notifications" icon="ti-bell"/> -->
      </template>
      <mobile-menu id="mobile-menu">
        <li class="nav-item">
          <p-button class="logout" round outline block @click.native="logout">
            登出
          </p-button>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link">
            <i class="ti-panel"></i>
            <p>狀態</p>
          </a>
        </li> -->
        <!-- <drop-down class="nav-item"
                   title="5 Notifications"
                   title-classes="nav-link"
                   icon="ti-bell">
          <a class="dropdown-item">通知 1</a>
          <a class="dropdown-item">通知 2</a>
          <a class="dropdown-item">通知 3</a>
          <a class="dropdown-item">通知 4</a>
          <a class="dropdown-item">其他通知</a>
        </drop-down> -->
        <!-- <li class="nav-item">
          <a class="nav-link">
            <i class="ti-settings"></i>
            <p>設定</p>
          </a>
        </li> -->
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar @TopNavBarLogout="logout"></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss" scoped>
#mobile-menu{
  li{
    display: flex;
    justify-content: center;
    align-items: center;
    .logout{
      width: 70%;
      height: 35px;
      margin-right: 25px;
    };
  }
};
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";

import axios from 'axios';
import LogoutNotification from '@/components/Notifications/LogoutNotification';

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  data() {
    return {
      api_baseUrl: this.$store.state.api_baseUrl,
      permission: this.$store.getters.permission,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout(){
      axios.delete(this.api_baseUrl + '/login').then((res) => {}).catch((err) => console.log(err));
      this.$notify({
        component: LogoutNotification,
        icon: "ti-info",
        horizontalAlign: "center",
        verticalAlign: "top",
        type: "success"
      });
      this.$store.commit("resetUserLogin");
      this.$router.push({name:'Login'});
      // console.log("logout");
    },
  }
};
</script>
