<template>
  <div class="form">
    <form>
      <slot name="SearchConditions">[Slot SearchConditions Default]</slot>
    </form>
  </div>
</template>

<script>
import Datepicker from '@/components/Datepicker/Datepicker.vue';
import Select from '@/components/Select/Select.vue';

export default {
  name: 'BaseSearchForm',
  components: {
    Datepicker,
    Select,
  },
  methods: {
    transData() {
      this.$emit('updateConditions', {
        formNumber: this.formNumber,
        deliveryNumber: this.deliveryNumber,
        shipDate: this.shipDate,
        orderDate: this.orderDate,
        deliveryDate: this.deliveryDate,
        orderPeople: this.orderPeople,
        takePeople: this.takePeople,
        orderType: this.orderType,
        orderStatus: this.orderStatus,
      });
    },
    handleShipStartDateChange(value) {
      this.shipDate.startDate = value;
    },
    handleShipEndDateChange(value) {
      this.shipDate.endDate = value;
    },
    handleOrderStartDateChange(value) {
      this.orderDate.startDate = value;
    },
    handleOrderEndDateChange(value) {
      this.orderDate.endDate = value;
    },
    handleDeliveryStartDateChange(value) {
      this.deliveryDate.startDate = value;
    },
    handleDeliveryEndDateChange(value) {
      this.deliveryDate.endDate = value;
    },
    handleOrderTypeChange(value) {
      this.orderType = value;
    },
    handleOrderStatusChange(value) {
      this.orderStatus = value;
    },
  },
};
</script>

<style scoped lang="scss">

.form__input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.form__datepicker{
  margin-left: 15px;
  margin-right:15px
}

.form__col--body .form__datepicker:first-child{
  margin-right: 15px;
  margin-left: 0;
}

.form__col--body .form__datepicker:last-child{
  margin-left: 15px;
  margin-right: 0;
}

.form__btn {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  padding: 10px;
}

.form__btn-primary{
  color: #fff;
  background-color: #0d6efd;
}

.btn_search{
  width: 75px;
  font-size: 17.5px;
}

.btn_clear{
  width: 75px;
  margin-left: 20px;
  font-weight: bold;
  font-size: 17.5px;
}
</style>
