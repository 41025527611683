<template>
  <BaseSearchForm>
    <template #SearchConditions>
      <SingleRowCondition>
        <template #Text>
          <label for="user_name">使用者名稱：</label>
        </template>
        <template #Content>
          <input
            class="form__input"
            id="user_name"
            v-model="user_name"
            name="user_name"
            type="text"
            v-on:oninput="limit_input_maxlength"
            maxlength="20"
          />
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="user_account">帳號：</label>
        </template>
        <template #Content>
          <input
            class="form__input"
            id="user_account"
            v-model="user_account"
            name="user_account"
            type="text"
            v-on:oninput="limit_input_maxlength"
            maxlength="20"
          />
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>貨主：</label>
        </template>
        <template #Content>
          <el-select v-model="company_id" placeholder="請選擇貨主">
            <el-option
              v-for="item in companies"
              :key="item.company_id"
              :label="item.company_name"
              :value="item.company_id"
            ></el-option>
          </el-select>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>狀態：</label>
        </template>
        <template #Content>
          <el-select v-model="Status" placeholder="請選擇狀態">
            <el-option
              v-for="item in StatusList"
              :key="item.id"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </template>
      </SingleRowCondition>
      <div class="form__row">
        <div class="form__col form__col--head">
          <el-button type="button" size="small" @click="transData"
            >搜尋</el-button
          >
          <el-button type="button" size="small" @click="clearData"
            >清空</el-button
          >
        </div>
      </div>
    </template>
  </BaseSearchForm>
</template>

<script>
import SearchFormMixin from "@/mixins/SearchFormMixin.js";
import BaseSearchForm from "@/components/Form/BaseSearchForm.vue";
import SingleRowCondition from "@/components/Form/SingleRowCondition";
import axios from "axios";
export default {
  name: "UserManagementForm",
  mixins: [SearchFormMixin],
  props: {
    companies: {
      default: [],
      type: Array,
    },
  },
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  data() {
    return {
      user_name: "",
      user_account: "",
      company_id: "",
      StatusList: [
        { id: 1, text: "不限", value: "all" },
        { id: 2, text: "啟用", value: true },
        { id: 3, text: "停用", value: false },
      ],
      Status: "",
      api_baseUrl: this.$store.state.api_baseUrl,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    limit_input_maxlength() {
      if (this.value.length > this.maxLength)
        this.value = this.value.slice(0, this.maxLength);
    },
    transData() {
      this.$emit("searchUser", {
        user_name: this.user_name,
        user_account: this.user_account,
        company_id: this.company_id,
        Status: this.Status,
      });
    },
    clearData() {
      this.user_name = "";
      this.user_account = "";
      this.company_id = "";
      this.Status = "";
    },
    handleStatusChange(value) {
      this.Status = value;
    },
    handleCompanyChange(value) {
      this.company_id = value;
    },
  },
};
</script>

<style lang="scss">
#UserManagementFormFeatures {
  display: flex;
}
</style>
