<template>
  <input type="date" :min="min" :max="max" :value="date" @change="handleOnChange"/>
</template>

<script>
export default {
  name: 'Datepicker.vue',
  props: ['date', 'min', 'max'],
  methods: {
    handleOnChange(e) {
      // console.log(e.target.value);
      this.$emit('update', e.target.value);
    },
  },
};
</script>

<style scoped>

</style>
