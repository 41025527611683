/*!

 =========================================================
 * Vue Paper Dashboard - v2.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router/index';
import { Table, TableColumn, Button, Input, Select, Option, Switch } from 'element-ui';
import PaperDashboard from './plugins/paperDashboard';
import 'vue-notifyjs/themes/default.css';

import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.withCredentials = true; // Set Axios send all cookie by defualt.

Vue.use(PaperDashboard);
Vue.use(VueAxios, axios);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Switch);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
