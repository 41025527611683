<template>
  <div class="tableContainer">
    <el-table
      :data="showData"
      empty-text="Loading..."
    >
      <div class="table__cell">
        <template v-for="(item, index) in filterColumns">
          <el-table-column
            :key="item.id"
            :label="item.name"
            :prop="item.engName"
            :index="index"
          >
            <template slot-scope="scope">
              <a v-if="scope.column.property==='BOLNumber'&&
              scope.row['C_Address3']==='6'&&
              route_name==='order_status'"
              :href="`https://www.t-cat.com.tw/inquire/TraceDetail.aspx?BillID=${scope.row[scope.column.property]}&ReturnUrl=Trace.aspx`"
              target="_blank"
              >
                {{Array.isArray(scope.row.BOLNumber)?"":scope.row.BOLNumber}}
              </a>
              <a v-else-if="scope.column.property==='BOLNumber'&&
              scope.row['C_Address3']==='A'&&
              route_name==='order_status'"
              :href="`http://query2.e-can.com.tw/self_link/id_link.asp?txtMainID=${scope.row[scope.column.property].toString().slice(0,-3)}`"
              target="_blank"
              >
                {{Array.isArray(scope.row.BOLNumber)?"":scope.row.BOLNumber}}
              </a>
              <a v-else-if="scope.column.property==='OrderKey'&&
              route_name==='order_status'"
              href="javascript:void(0);"
              @click.prevent="goto_order_detail(scope.row)"
              >
                {{Array.isArray(scope.row.OrderKey)?"":scope.row.OrderKey}}
              </a>
              <label v-else>{{
                Array.isArray(scope.row[scope.column.property])?"":scope.row[scope.column.property]
                }}
              </label>
            </template>
        </el-table-column>
        </template>
        <el-table-column width="1"/>
      </div>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'Table',
  props: {
    dataColumns: {
      default: function () {
        return []
      },
      type: Array
    },
    showData: {
      default: function () {
        return []
      },
      type: Array
    },
    dataLoaded: {
      default: function () {
        return false
      },
      type: Boolean
    },
    which_page: {
      default: function () {
        return ""
      },
      type: String
    },
  },
  data() {
    return {
      route_name: this.$route.name,
    };
  },
  // computed: {
    // transOrderType() {
    //   return (val) => this.orderTypeList[val].text;
    // },
    // transOrderStatus() {
    //   return (val) => this.orderStatusList[val].text;
    // },
  // },
  computed:{
    filterColumns() {
      // console.log(this.dataColumns);
      return this.dataColumns;
    }
  },
  methods:
  {
    goto_order_detail(scope_row){
      this.$router.push(`order_detail/${scope_row.OrderKey}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.tableContainer{
  overflow: auto;
  width: 100%;
}
</style>
<style lang="scss" scoped>
:deep .table__cell .el-table td.el-table__cell div{
  max-height: 20px;
  margin-top: -8px;
  margin-bottom: -6px;
};
</style>
