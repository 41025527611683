<template>
  <div>
    <BaseSearchPage
      :isSearch="isSearch"
      :which_api="which_api"
      @updatePerPage="updatePerPage">
      <template #SearchPageForm>
        <PurchaseForm
          @updateConditions="getData"
          :orderStatusList="orderStatusList"
        />
      </template>
      <template #SearchPageChooser>
        <Chooser :columns="columns" @updateSelectColumns="updateSelectColumns"/>
      </template>
      <template #SearchPageChooserDemo>
        <h3>查詢結果欄位預覽：</h3>
        <PreviewColumns
          :dataColumns="selectColumns"/>
      </template>
      <template #SearchPageTable>
        <Table
          :showData="orderData"
          :dataColumns="selectColumns"
          v-show="isSearch"
        />
      </template>
      <template #SearchPagePagination>
        <Pagination :currentPage="pagination.currentPage"
                    :totalPage="pagination.totalPage"
                    :numPerPage="pagination.perPage"
                    @updateCurrentPage="updateCurrentPage"
                    v-show="isSearch"
        ></Pagination>
      </template>
    </BaseSearchPage>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSearchPage from '@/views/BaseSearchPage.vue'
import SearchPageMixin from '@/mixins/SearchPageMixin.js'
import PurchaseForm from '@/components/Form/PurchaseForm.vue';
import getOrderStatusMixin from '@/mixins/CustomColumns/getOrderStatusMixin.js'
import axios from 'axios';

export default {
  name: 'Purchase',
  mixins: [SearchPageMixin, getOrderStatusMixin],
  components: {
    BaseSearchPage,
    PurchaseForm,
  },
  data() {
    return {
      isSearch: false,
      which_columns: 'PO_ResultsColumns',
      custom_columns_id: 'POStatus_Dropdown',
      which_api: 'PurchaseOrder',
      api_baseUrl: this.$store.state.api_baseUrl + '/',
      dataLoaded: false,
      search_conditions: '',
      search_again: false
    };
  },
  created() {
  },
  methods:{
    limit_input_maxlength(){
      if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
    },
    getData(value){
      // Fix page wrong when change search condition and not at page 1.
      if(this.search_again === true){
        this.pagination.currentPage = 1;
      };
      // console.log(value);
      this.isSearch = true;
      this.search_conditions = value;
      axios.post(this.api_baseUrl + this.which_api, {
        search_conditions: this.search_conditions,
        perPage: this.pagination.perPage,
        pageNumber: this.pagination.currentPage,
      }).then((res) => {
        let results = res.data["Results"];
        this.orderData = results;
        this.pagination.totalCount = results[0]["TotalCount"]
        this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage);
        this.dataLoaded = true;
        // console.log("orderData", results)
        // console.log(results)
        // Fix page wrong when change search condition and not at page 1.
        if(this.pagination.currentPage != 1 && results[0]["TotalCount"].length === 0){
          this.search_again = true;
          this.getData(value);
        } else{
          this.search_again = false;
        };
      }).catch((err) => console.log(err));
      // console.log(conditions)
    },
  },
};
</script>

<style scoped lang="scss">
</style>