<template>
  <select
    :value="selected_value"
    @change="select_value_change"
  >
    <option
        key="-1"
        value=""
        :selected="'' === selected_value"
    >
      請選擇
    </option>
    <option
      v-for="option in options"
      :key="option.id"
      :value="option.value"
      :selected="option === selected_value"
    >
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'Select',
  props: {
    selected_value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
  },
  methods: {
    select_value_change($event){
      this.$emit('change', $event.target.value);
    },
  },
};
</script>

<style scoped land="scss">
select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}
</style>
