<template>
  <div>
    <h1>查詢條件</h1>
    <slot name="SearchPageForm">[Slot SearchPageForm Default]</slot>
    <h1>表格欄位設定</h1>
    <slot name="SearchPageChooser">[Slot SearchPageChooser Default]</slot>
    <slot name="SearchPageChooserDemo">[Slot SearchPageChooserDemo Default]</slot>
    <h1 v-if="isSearch">查詢結果 </h1>
    <div class="results-features" id="results-features" v-show="isSearch">
      <div class="results-features-1" id="results-features-1">
        <button class="form__btn form__btn-primary"
                  type="button"
                  @click="ClickPrintExcel"
                  >列印 Excel</button>
      </div>
      <div class="results-features-2" id="results-features-2">
        <label for="results-qty-onepage">每頁要顯示的數量:</label>
        <select name="results-qty-onepage" id="results-qty-onepage" v-model="results_qty_onepage" @change="updatePerPage">
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>

    <slot name="SearchPageTable" v-show="isSearch">[Slot SearchPageTable Default]</slot>
    <div class="paginationCntr" v-show="isSearch">
      <slot name="SearchPagePagination">[Slot SearchPagePagination Default]</slot>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// @ is an alias to /src
export default {
  name: 'BaseSearchPage',
  props: {
    'isSearch': {
      type: Boolean,
      default: false
    },
    'which_api': {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      results_qty_onepage: 15,
      api_baseUrl: this.$store.state.api_baseUrl + '/',
      route_name: this.$route.name,
    };
  },
  methods: {
    updatePerPage(){
      // console.log('results_qty_onepage changed:', this.results_qty_onepage)
      this.$emit('updatePerPage', this.results_qty_onepage);
    },
    ClickPrintExcel(){
      // console.log("ClickPrintExcel");
      axios.post(this.api_baseUrl + 'SearchdataDownloadCSV', {
        which_api: this.which_api,
        responseType: 'blob',
      }).then((data) => {
        if (!data) {
            return ;
        };
        let filename = this.route_name + "_export.csv";
        let binaryData = [];
        binaryData.push("\uFEFF" + data.data);
        let url = window.URL.createObjectURL(new Blob(binaryData));
        let link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = filename;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped lang="scss">
.btnContainer{
  overflow: hidden;
  margin-top:10px;
}

.paginationCntr{
  display:flex;
  justify-content: center;
  margin-top:20px;
}
.stepBtn{ width:50px; height:50px; border-radius:50%; font-size: 28px; cursor:pointer;}
.stepBtn--next{ float: right;}
.stepBtn--prev{ float: left;}
.stepBtn:after{clear:both}
.stepBtn-primary{
  border: 1px solid #0d6efd;
  background-color: #0d6efd;
  color: #fff;
}
.stepBtn-success{
  border: 1px solid #90ee90;
  background-color: #90ee90;
  color: #fff;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}

// .slide-fade-leave-active {}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100vh);
  opacity: 0;
}

.form__btn {
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  padding: 10px;
}

.form__btn-primary{
  color: #fff;
  background-color: #24a84c;
}

#results-features{
  max-width: 275px;
  display: flex;
  justify-content: space-between;
}

#results-features-2{
  padding-top: 10px;
}
</style>