import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';

// GeneralViews
import NotFound from '@/pages/NotFoundPage.vue';

// Pages no need login
import Login from '@/views/Login.vue';

// Pages need login
import Notifications from '@/pages/Notifications.vue';
import Home from '@/views/Home.vue';
import Purchase from '@/views/Purchase.vue';
import GoodsChange from '@/views/GoodsChange.vue';
import Inventory from '@/views/Inventory.vue';
import Shipping from '@/views/Shipping.vue';
import OrderStatus from '@/views/OrderStatus.vue';
import InventoryShelfDay from '@/views/InventoryShelfDay.vue';
import OrderDetail from '@/views/OrderDetail.vue';
import ChangePassword from '@/views/ChangePassword.vue';

// Admin pages
import Dashboard from '@/pages/Dashboard.vue';
import UserProfile from '@/pages/UserProfile.vue';
import Icons from '@/pages/Icons.vue';
import Maps from '@/pages/Maps.vue';
import Typography from '@/pages/Typography.vue';
import TableList from '@/pages/TableList.vue';
import UserManagement from '@/views/UserManagement.vue';
import AddUser from '@/views/AddUser.vue';


Vue.use(Router);

const router = new Router({
  routes : [
    {
      path: '/',
      component: DashboardLayout,
      redirect: '/home',
      children: [
        {
          path: 'home',
          name: 'home',
          component: Home,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'stats',
          name: 'stats',
          component: UserProfile,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'notifications',
          name: 'notifications',
          component: Notifications,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'icons',
          name: 'icons',
          component: Icons,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'maps',
          name: 'maps',
          component: Maps,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'typography',
          name: 'typography',
          component: Typography,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: 'table-list',
          name: 'table-list',
          component: TableList,
          meta: {
            requiresAuth: true,
          }
        },
        {
          path: '/purchase',
          name: 'purchase',
          component: Purchase,
          meta: {
            pageName: '進貨資料查詢',
            requiresAuth: true,
          }
        },
        {
          path: '/goods_change',
          name: 'goods_change',
          component: GoodsChange,
          meta: {
            pageName: '貨品進出異動',
            requiresAuth: true,
          }
        },
        {
          path: '/inventory',
          name: 'inventory',
          component: Inventory,
          meta: {
            pageName: '貨品庫存查詢',
            requiresAuth: true,
          }
        },
        {
          path: '/shipping',
          name: 'shipping',
          component: Shipping,
          meta: {
            pageName: '出貨資料查詢',
            requiresAuth: true,
          }
        },
        {
          path: '/order_status',
          name: 'order_status',
          component: OrderStatus,
          meta: {
            pageName: '訂單狀態查詢',
            requiresAuth: true,
          }
        },
        {
          path: '/inventory_shelfday',
          name: 'inventory_shelfday',
          component: InventoryShelfDay,
          meta: {
            pageName: '貨品庫存查詢(上架日)',
            requiresAuth: true,
          }
        },
        {
          path: '/user',
          name: 'user',
          component: UserManagement,
          meta: {
            pageName: '使用者管理',
            requiresAuth: true,
          }
        },
        {
          path: '/user/add',
          name: 'userAdd',
          component: AddUser,
          meta: {
            pageName: '使用者新增',
            requiresAuth: true,
          }
        },
        {
          path: '/user/:id',
          name: 'userEdit',
          component: AddUser,
          meta: {
            pageName: '使用者編輯',
            requiresAuth: true,
          }
        },
        {
          path: '/order_detail/:id',
          name: 'order_detail',
          component: OrderDetail,
          meta: {
            pageName: '訂單明細',
            requiresAuth: true,
          }
        },
        {
          path: '/change_password',
          name: 'change_password',
          component: ChangePassword,
          meta: {
            pageName: '修改密碼',
            requiresAuth: true,
          }
        }
      ]
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
    },
    { path: '*', component: NotFound }
  ]
});

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/
router.beforeResolve(async (to, from, next) => {
  const isLogin = store.getters.isLogin;

  // if goto Login page but already login, goto Home page
  if (to.name === 'Login' && isLogin) {
    next({ name: 'home'});
  }

  // if need login but not login, goto Login page
  if (to.meta.requiresAuth && !isLogin) {
    next({ name: 'Login' });
  } else {
    next();
  }
});
export default router;
