<template>
  <div class="pagination">
    <button
      class="btn"
      type="button"
      :disabled="currentPage == 1"
      :hidden="!totalPage"
      @click="prev"
    > <span class="ti-angle-double-left"></span> </button>
    <button
      v-for="val in calcPages"
      :key="val"
      class="btn"
      :class="{
        'btn-active': val === currentPage,
      }"
      :value="val"
      @click="goPage(val)"
      type="button"
    >
      {{ val }}
    </button>
    <button
      class="btn"
      type="button"
      :disabled="currentPage == totalPage"
      :hidden="!totalPage"
      @click="next"
    > <span class="ti-angle-double-right"></span> </button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: Number,
    totalPage: Number,
  },
  computed: {
    calcPages() {
      const pageList = [];

      let startIndex = this.currentPage - 5 > 1 ? this.currentPage - 5 : 1;
      let endIndex = this.currentPage + 4 > this.totalPage ? this.totalPage : this.currentPage + 4;
      // console.log("currentPage:",this.currentPage);
      // console.log("startIndex:", startIndex);
      // console.log("endIndex:", endIndex);
      if (this.currentPage <= 5 && this.totalPage > 10) {
        endIndex = 10;
      } else if (endIndex === this.totalPage) {
        endIndex = this.totalPage;
        // startIndex = endIndex - 9;
      }
      // console.log("for start:", startIndex);
      // console.log("for end:", endIndex);
      for (let i = startIndex; i <= endIndex; i += 1) {
        pageList.push(i);
      }

      return pageList;
    },
  },
  methods: {
    prev() {
      if (this.currentPage - 10 >= 1) {
        this.$emit('updateCurrentPage', this.currentPage - 10);
      } else {
        this.$emit('updateCurrentPage', 1);
      }
    },
    next() {
      if (this.currentPage + 10 <= this.totalPage) {
        this.$emit('updateCurrentPage', this.currentPage + 10);
      } else {
        this.$emit('updateCurrentPage', this.totalPage);
      }
    },
    goPage(page) {
      this.$emit('updateCurrentPage', page);
    },
  },
};
</script>

<style scoped>

.pagination {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.pagination .btn{
    display: block;
 }

.btn {
  border-radius:10px;
  padding: 10px;
  background-color:#fff;
  color:#000;
  min-width:40px;
  cursor:pointer;
}

.btn-active{
  background-color:#0d6efd;
  color: #fff;
  cursor: default;
}

.btn[disabled]{
  cursor: default;
}

</style>
