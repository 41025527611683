<template>
  <div>
    <router-link :to="{ path: '/order_status' }">
      <el-button type="button" size="medium">返回</el-button>
    </router-link>
    <h1>訂單明細</h1>
    <el-table :data='OrderDetailData' style="width: 100%">
      <el-table-column
        prop="OrderKey"
        label="出貨單號">
      </el-table-column>
      <el-table-column
        prop="item_serial"
        label="Seq">
      </el-table-column>
      <el-table-column
        prop="Sku"
        label="產品編號">
      </el-table-column>
      <el-table-column
        prop="Descr"
        label="產品名稱">
      </el-table-column>
      <el-table-column
        prop="Lottable07"
        label="版本/批號">
      </el-table-column>
      <el-table-column
        prop="is_good"
        label="良品/不良品">
      </el-table-column>
      <el-table-column
        prop="Qty"
        label="數量">
      </el-table-column>
      <el-table-column
        prop="Notes"
        label="主檔備註">
      </el-table-column>
      <el-table-column
        prop="Lottable06"
        label="商品備註">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination/Pagination.vue';
import axios from 'axios';
export default {
  name: 'OrderDetail',
  // mixins: [],
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        currentPage: 1,
        totalPage: 0,
        perPage: 1,
      },
      OrderDetailData: [],
      companies: [],
      api_baseUrl: this.$store.state.api_baseUrl + '/',
      rowData_backup: [],
      which_api: 'OrderDetail'
    };
  },
  created() {
    this.getData();
  },
  computed:{

  },
  methods:{
    getData(){
      this.isSearch = true;
      this.search_conditions = {OrderKey: this.$route.params.id};
      axios.post(this.api_baseUrl + this.which_api, {
        search_conditions: this.search_conditions,
        perPage: 200,
        pageNumber: 1,
      }).then((res) => {
        let results = res.data["Results"];
        this.OrderDetailData = results;
        this.pagination.totalCount = results[0]["TotalCount"]
        this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage);
        this.dataLoaded = true;
        // console.log("OrderDetailData", results)
      }).catch((err) => console.log(err));
    },
    updateCurrentPage(targetPage) {
      this.pagination.currentPage = targetPage;
    },
    updatePerPage(qty){
      this.results_qty_onepage = qty
    },
  },
};
</script>

<style lang="scss">
</style>
