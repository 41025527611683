<template>
  <span class="tag" @click="$emit('tag-click')">{{text}}</span>
</template>

<script>
export default {
  name: 'Tag',
  props: ['text'],
};
</script>

<style scoped>
 .tag{
   box-sizing: border-box;
   border-radius: 20px;
   border: 1px solid #0d6efd;
   background-color: #0d6efd;
   color: #fff;
   width: auto;
   height: 25px;
   padding: 5px 10px;
 }
</style>
