// @ is an alias to /src

export default {
    data() {
      return {
        orderStatusList: [],
      };
    },
    created() {
      this.getOrderStatus_Dropdown();
    },
    methods:{
        getOrderStatus_Dropdown() {
          this.axios.get(this.api_baseUrl + '/CustomDropdown', { params: { 'custom_columns_id': this.custom_columns_id}}).then((res) => {
            this.orderStatusList = res.data;
            // console.log('orderStatusList:', this.orderStatusList);
          })
            .catch((err) => console.log(err));
        },
      },
};
