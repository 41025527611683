<template>
  <BaseSearchForm>
    <template #SearchConditions>
      <SingleRowCondition>
        <template #Text>
          <label for="SKU">貨號：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="SKU"
                 v-model="SKU"
                 name="SKU"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="DESCR">貨品名稱：</label>
        </template>
        <template #Content>
          <input class="form__input"
                 id="DESCR"
                 v-model="DESCR"
                 name="DESCR"
                 type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>異動日期：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleChangeStartDateChange"
                      :date="changeDate.startDate"
                      :max="changeDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleChangeEndDateChange"
                      :date="changeDate.endDate"
                      :min="changeDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>效期：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleLottable05StartDateChange"
                      :date="Lottable05.startDate"
                      :max="Lottable05.endDate"
          />
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleLottable05EndDateChange"
                      :date="Lottable05.endDate"
                      :min="Lottable05.startDate"/>
        </template>
      </SingleRowCondition>
      <div class="form__row">
        <div class="form__col form__col--head">
          <button class="form__btn form__btn-primary btn_search"
                  type="button"
                  @click="transData"> 搜尋</button>
          <button class="form__btn form__btn-default btn_clear"
                  type="button"
                  @click="clearData"> 清空 </button>
        </div>
      </div>
    </template>
  </BaseSearchForm>
</template>

<script>
import SearchFormMixin from '@/mixins/SearchFormMixin.js'
import BaseSearchForm from '@/components/Form/BaseSearchForm.vue';
import SingleRowCondition from '@/components/Form/SingleRowCondition';

export default {
  name: 'GoodsChangeForm',
  mixins: [SearchFormMixin],
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  data() {
    return {
      changeDate: {
        startDate: '',
        endDate: '',
      },
      Lottable05: {
        startDate: '',
        endDate: '',
      },
      DESCR:'',
      SKU:'',
    };
  },
  created() {
  },
  computed: {},
  mounted() {
  },
  methods: {
    transData() {
      if(
        this.changeDate.startDate == '' &&
        this.changeDate.endDate == '' &&
        this.Lottable05.startDate == '' &&
        this.Lottable05.endDate == '' &&
        this.DESCR == '' &&
        this.SKU == ''
      ){
        alert("搜尋條件不得為空，請輸入搜尋條件後重試。");
      } else {
          this.$emit('updateConditions', {
          changeDate: this.changeDate,
          Lottable05: this.Lottable05,
          SKU: this.SKU,
          DESCR: this.DESCR,
        });
      };
    },
    clearData(){
      this.changeDate.startDate= null;
      this.changeDate.endDate= null;
      this.Lottable05.startDate= null;
      this.Lottable05.endDate= null;
      this.DESCR='';
      this.SKU='';
    },
    handleChangeStartDateChange(value) {
      this.changeDate.startDate = value;
    },
    handleChangeEndDateChange(value) {
      this.changeDate.endDate = value;
    },
    handleLottable05StartDateChange(value) {
      this.Lottable05.startDate = value;
    },
    handleLottable05EndDateChange(value) {
      this.Lottable05.endDate = value;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
