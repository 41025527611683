<template>
  <div>
    <SingleRowCondition>
      <template #Text>
        <label for="password">原密碼：</label>
      </template>
      <template #Content>
        <input
          class="form__input"
          id="password"
          v-model="password"
          name="password"
          type="password"
        />
      </template>
    </SingleRowCondition>
    <SingleRowCondition>
      <template #Text>
        <label for="new_password">新密碼：</label>
      </template>
      <template #Content>
        <input
          class="form__input"
          id="new_password"
          v-model="new_password"
          name="new_password"
          type="password"
        />
      </template>
    </SingleRowCondition>
    <SingleRowCondition>
      <template #Text>
        <label for="passwordAgain">新密碼確認：</label>
      </template>
      <template #Content>
        <input
          class="form__input"
          id="passwordAgain"
          v-model="new_password_again"
          name="passwordAgain"
          type="password"
        />
      </template>
    </SingleRowCondition>
    <button @click="changePassword">修改密碼</button>
  </div>
</template>

<script>
import SingleRowCondition from "@/components/Form/SingleRowCondition";
import axios from "axios";

export default {
  name: "ChangePassword.vue",
  components: {
    SingleRowCondition,
  },
  data() {
    return {
      api_baseUrl: this.$store.state.api_baseUrl + '/',
      user_id: this.$store.getters.user_id,
      user_account: this.$store.getters.user_account,
      password: "",
      new_password: "",
      new_password_again: "",
    };
  },
  methods: {
    changePassword() {
      if (this.new_password !== this.new_password_again) {
        alert("兩次密碼輸入不一致");
      } else{
        axios
        .put(this.api_baseUrl + "/UserChangePassword", {
          user_id: this.user_id,
          user_account: this.user_account,
          user_password: this.password,
          new_password: this.new_password
        })
        .then((res) => {
          let results = res.data;
          // console.log(res.data);
          if (results["Status"] === "Success") {
            alert("更新成功");
          } else {
            if (results["ErrorCode"] === "UCP001") {
              alert("更新失敗，使用者不存在，或帳號密碼錯誤。錯誤代碼: UCP001。");
            } else if (results["ErrorCode"] === "user002") {
              alert(
                "更新失敗，密碼強度不足，密碼長度需介於 8~20，須包含大小寫字母、數字與特殊符號至少各 1。錯誤代碼: user002。"
              );
            } else if (results["ErrorCode"] === "Exception") {
              alert("更新失敗，系統錯誤。錯誤代碼: Exception。");
            } else {
              alert("更新失敗，未知錯誤。錯誤代碼: unknown");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alert("更新失敗。錯誤代碼: unknwon。");
        });
      };
    },
  },
};
</script>

<style scoped></style>
