<template>
  <div class="tableContainer">
    <el-table
      :data="showData"
      empty-text=" "
      :row-style="classChecker"
    >
      <div class="table__cell">
        <template v-for="(item, index) in filterColumns">
          <el-table-column
            :key="item.id"
            :label="item.name"
            :prop="item.engName"
            :index="index"
          >
          </el-table-column>
        </template>
        <el-table-column width="1"/>
      </div>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'PreviewColumns',
  props: {
    dataColumns: {
      default() {
        return []
      },
      type: Array
    },
    showData: {
      default() {
        return []
      },
      type: Array
    }
  },
  computed: {
    filterColumns() {
      // console.log(this.dataColumns);
      return this.dataColumns;
    },
  },
  methods: {
    classChecker({ row, column }) {
      return "hide-empty-block"
    },
  }
};
</script>

<style lang="scss" scoped>
// .tableContainer{
//   overflow: auto;
//   width: 100%;
// }
.hide-empty-block {
  display: none;
  height: 0px;
}
// :deep .el-table__empty-block {
//   display: none;
//   // min-height: 0px;
// };
// :deep .el-table__empty-text {
//   display: none;
// }
// :deep .el-table__body-wrapper .is-scrolling-none{
//   display: none;
//   height: 0px;
// }
</style>