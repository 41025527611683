// @ is an alias to /src
import Datepicker from '@/components/Datepicker/Datepicker.vue';
import Select from '@/components/Select/Select.vue';

export default {
    components: {
        Datepicker,
        Select,
    },
    data() {
        return {
            formNumber: 0,
            deliveryNumber: 0,
            shipDate: {
                startDate: '',
                endDate: '',
            },
            orderDate: {
                startDate: '',
                endDate: '',
            },
            deliveryDate: {
                startDate: '',
                endDate: '',
            },
            orderPeople: '',
            takePeople: '',
            orderType: '',
            orderStatus: '',
        };
    },
    created() {
    },
    computed: {},
    mounted() {
    },
};