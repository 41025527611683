<template>
  <footer class="footer">
    <div class="container-fluid d-flex flex-wrap justify-content-between">
      <nav>
        <ul>
          <li>
            <!-- <router-link :to="{path:'/admin'}">Dashboard</router-link> -->
          </li>
        </ul>
      </nav>
      <div class="copyright d-flex flex-wrap">
        &copy;
        <!-- <i class="fa fa-heart heart"></i> by
        <a href="https://github.com/cristijora" target="_blank"> &nbsp; Cristi Jora.</a>&nbsp; -->
        Designed by 漢果股份有限公司</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style>
</style>
