// @ is an alias to /src

export default {
    data() {
      return {
        orderTypeList: [],
      };
    },
    created() {
      this.getOrderType_Dropdown()
    },
    methods:{
      getOrderType_Dropdown() {
        this.axios.get(this.api_baseUrl + '/CustomDropdown', { params: { 'custom_columns_id': 'ShippingOrderType'}}).then((res) => {
          this.orderTypeList = res.data
        })
          .catch((err) => console.log(err));
      },
    },
};
