<template>
  <BaseSearchForm>
    <template #SearchConditions>
      <SingleRowCondition>
        <template #Text>
          <label for="RECEIPTKEY">進貨單號：</label>
        </template>
        <template #Content>
          <input class="form__input"
              id="RECEIPTKEY"
              v-model="RECEIPTKEY"
              name="RECEIPTKEY"
              type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="EXTERNRECEIPTKEY">客單編號：</label>
        </template>
        <template #Content>
          <input class="form__input"
              id="EXTERNRECEIPTKEY"
              v-model="EXTERNRECEIPTKEY"
              name="EXTERNRECEIPTKEY"
              type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>到貨日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                  @update="handleDeliveryStartDateChange"
                  :date="deliveryDate.startDate"
                  :max="deliveryDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleDeliveryEndDateChange"
                      :date="deliveryDate.endDate"
                      :min="deliveryDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>預計入貨日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                  @update="handleEXPECTEDRECEIPTDATEStartDateChange"
                  :date="ExpectedReceiptDate.startDate"
                  :max="ExpectedReceiptDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleEXPECTEDRECEIPTDATEEndDateChange"
                      :date="ExpectedReceiptDate.endDate"
                      :min="ExpectedReceiptDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>效期：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                  @update="handleLottable05StartDateChange"
                  :date="Lottable05.startDate"
                  :max="Lottable05.endDate"
          />
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleLottable05EndDateChange"
                      :date="Lottable05.endDate"
                      :min="Lottable05.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="SKU">貨號：</label>
        </template>
        <template #Content>
          <input class="form__input"
              id="SKU"
              v-model="SKU"
              type="text">
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="DESCR">貨品名稱：</label>
        </template>
        <template #Content>
          <input class="form__input"
              id="DESCR"
              v-model="DESCR"
              type="text">
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label>進貨單狀態：</label>
        </template>
        <template #Content>
          <Select
              :selected_value="orderStatus"
              @change="handleOrderStatusChange"
              :options="orderStatusList" />
        </template>
      </SingleRowCondition>
      <div class="form__row">
        <div class="form__col form__col--head">
          <button class="form__btn form__btn-primary btn_search"
                  type="button"
                  @click="transData"> 搜尋</button>
          <button class="form__btn form__btn-default btn_clear"
                  type="button"
                  @click="clearData"> 清空 </button>
        </div>
      </div>
    </template>
  </BaseSearchForm>
</template>

<script>
import SearchFormMixin from '@/mixins/SearchFormMixin.js'
import BaseSearchForm from '@/components/Form/BaseSearchForm.vue';
import SingleRowCondition from '@/components/Form/SingleRowCondition';

export default {
  name: 'PurchaseForm',
  mixins: [SearchFormMixin],
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  props: {
    orderStatusList: {
      default: [],
      type: Array
    }
  },
  data() {
    return {
      RECEIPTKEY: '',
      EXTERNRECEIPTKEY: '',
      SKU: '',
      DESCR: '',
      Lottable05: {
        startDate: '',
        endDate: '',
      },
      ExpectedReceiptDate: {
        startDate: '',
        endDate: '',
      },
      deliveryDate: {
        startDate: '',
        endDate: '',
      },
      orderStatus: '',
      set_default_orderStatus: '',
    };
  },
  created() {
  },
  computed: {},
  mounted() {
  },
  methods: {
    transData() {
      if(
        this.RECEIPTKEY == '' &&
        this.EXTERNRECEIPTKEY == '' &&
        this.SKU == '' &&
        this.DESCR == '' &&
        this.Lottable05.startDate == '' &&
        this.Lottable05.endDate == '' &&
        this.ExpectedReceiptDate.startDate == '' &&
        this.ExpectedReceiptDate.endDate == '' &&
        this.deliveryDate.startDate == '' &&
        this.deliveryDate.endDate == '' &&
        this.orderStatus == ''
      ){
        alert("搜尋條件不得為空，請輸入搜尋條件後重試。");
      } else {
        this.$emit('updateConditions', {
          RECEIPTKEY: this.RECEIPTKEY,
          EXTERNRECEIPTKEY: this.EXTERNRECEIPTKEY,
          SKU: this.SKU,
          DESCR: this.DESCR,
          Lottable05: this.Lottable05,
          ExpectedReceiptDate: this.ExpectedReceiptDate,
          deliveryDate: this.deliveryDate,
          orderStatus: this.orderStatus,
        });
      };
    },
    clearData(){
      this.RECEIPTKEY= '';
      this.EXTERNRECEIPTKEY= '';
      this.SKU= '';
      this.DESCR= '';
      this.Lottable05.startDate= '';
      this.Lottable05.endDate= '';
      this.ExpectedReceiptDate.startDate= '';
      this.ExpectedReceiptDate.endDate= '';
      this.deliveryDate.startDate = '';
      this.deliveryDate.endDate = '';
      this.orderStatus= '';
      this.set_default_orderStatus= '';
    },
    handleLottable05StartDateChange(value) {
      this.Lottable05.startDate = value;
    },
    handleLottable05EndDateChange(value) {
      this.Lottable05.endDate = value;
    },
    handleEXPECTEDRECEIPTDATEStartDateChange(value) {
      this.ExpectedReceiptDate.startDate = value;
    },
    handleEXPECTEDRECEIPTDATEEndDateChange(value) {
      this.ExpectedReceiptDate.endDate = value;
    },
    handleDeliveryStartDateChange(value) {
      this.deliveryDate.startDate = value;
    },
    handleDeliveryEndDateChange(value) {
      this.deliveryDate.endDate = value;
    },
    handleOrderStatusChange(value) {
      this.orderStatus = value;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
