<template>
  <div>
    <BaseSearchForm>
      <template #SearchConditions>
        <SingleRowCondition>
          <template #Text>
            <label for="account">帳號：</label>
          </template>
          <template #Content>
            <input
              class="form__input"
              id="account"
              v-model="account"
              name="account"
              type="text"
              v-on:oninput="limit_input_maxlength"
              v-if="route_name==='userAdd'"
              maxlength="20"
            />
            <label v-else>{{ account }}</label>
          </template>
        </SingleRowCondition>
        <SingleRowCondition>
          <template #Text>
            <label for="username">名稱：</label>
          </template>
          <template #Content>
            <input
              class="form__input"
              id="username"
              v-model="username"
              name="username"
              type="text"
              v-on:oninput="limit_input_maxlength"
              maxlength="20"
            />
          </template>
        </SingleRowCondition>
        <SingleRowCondition>
          <template #Text>
            <label for="password">密碼：</label>
          </template>
          <template #Content>
            <input
              class="form__input"
              id="password"
              v-model="password"
              name="password"
              type="password"
              v-on:oninput="limit_input_maxlength"
              maxlength="20"
            />
          </template>
        </SingleRowCondition>
        <SingleRowCondition>
          <template #Text>
            <label for="password_again">確認密碼：</label>
          </template>
          <template #Content>
            <input
              class="form__input"
              id="password_again"
              v-model="password_again"
              name="password_again"
              type="password"
              v-on:oninput="limit_input_maxlength"
              maxlength="20"
            />
          </template>
        </SingleRowCondition>
        <SingleRowCondition>
          <template #Text>
            <label for="company">貨主：</label>
          </template>
          <template #Content>
            <el-select
              v-model="company_id"
              placeholder="請選擇貨主"
            >
              <el-option
                v-for="item in companies"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id"
              ></el-option>
            </el-select>
          </template>
        </SingleRowCondition>
        <SingleRowCondition>
          <template #Text>
            <label for="user_enable">啟用：</label>
          </template>
          <template #Content>
            <el-switch
              v-model="user_enable"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>

          </template>
        </SingleRowCondition>
        <div class="form__row">
          <div class="form__col form__col--head">
            <el-button type="button" @click="save">儲存</el-button>
            <router-link :to="{name:'user'}"><el-button type="button">返回</el-button></router-link>
          </div>
        </div>
      </template>
    </BaseSearchForm>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseSearchForm from "@/components/Form/BaseSearchForm.vue";
import SingleRowCondition from "@/components/Form/SingleRowCondition";
import axios from "axios";

export default {
  name: "AddUser",
  mixins: [],
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  data() {
    return {
      isSearch: false,
      api_baseUrl: this.$store.state.api_baseUrl,
      companies: [],
      account: "",
      password: "",
      password_again: "",
      username: "",
      company_id: "",
      user_enable: false,
      route_name: this.$route.name,
    };
  },
  created() {

  },
  mounted() {
    this.get_companies();
    if(this.route_name === 'userEdit') {
      this.get_user();
    }
  },
  methods: {
    limit_input_maxlength() {
      if (this.value.length > this.maxLength)
        this.value = this.value.slice(0, this.maxLength);
    },
    save() {
      if (
        this.company_id === "" ||
        this.username === "" ||
        this.route_name === "userAdd" &&
        (
          this.account === "" ||
          this.password === "")
      ) {
        alert("新增失敗，請檢查是否有項目尚未輸入。");
      } else if (this.password === this.password_again) {
        if(this.route_name === 'userEdit') {
          axios
            .put(this.api_baseUrl + "/User", {
              user_id: this.$route.params.id,
              user_account: this.account,
              user_password: this.password,
              user_name: this.username,
              company_id: this.company_id,
              user_enable: this.user_enable,
            })
            .then((res) => {
              let results = res.data;
              // console.log(res.data);
              if (results["Status"] === "Success") {
                alert("更新成功");
              } else {
                // console.log("ErrorCode: " + results["ErrorCode"])
                let ErrorCode = results["ErrorCode"]
                this.handle_api_errorcode(ErrorCode)
              }
            })
            .catch((err) => {
              console.log(err);
              alert("更新失敗。錯誤代碼: unknwon。");
            });
        } else if (this.route_name === 'userAdd') {
          axios
            .post(this.api_baseUrl + "/User", {
              user_account: this.account,
              user_password: this.password,
              user_name: this.username,
              company_id: this.company_id,
              user_enable: this.user_enable,
            })
            .then((res) => {
              let results = res.data;
              // console.log(results);
              if (results["Status"] === "Success") {
                alert("新增成功");
              } else {
                // console.log("ErrorCode: " + results["ErrorCode"])
                let ErrorCode = results["ErrorCode"]
                this.handle_api_errorcode(ErrorCode)
              }
            })
            .catch((err) => {
              console.log(err);
              alert("新增失敗。錯誤代碼: unknwon。");
            });
        } else {
          alert("Error, Wrong $Router.name");
        }
      } else {
        alert("儲存失敗，兩次輸入的密碼不相同。");
      }
    },
    get_companies() {
      axios
        .get(this.api_baseUrl + "/Company")
        .then((res) => {
          this.companies = res.data;
        })
        .catch((err) => console.log(err));
    },
    get_company_name(company_id) {
      let company = this.companies.filter(
        (item) => item.company_id == company_id
      )[0];
      return company.company_name;
    },
    get_user() {
      this.isSearch = true;
      axios
        .get(this.api_baseUrl + "/User", {
          params: {
            user_id: this.$route.params.id
          },
        })
        .then((res) => {
          let results = res.data["Results"];
          if(results.length != 1){
            // console.log("length != 1," + results.length);
            return False;
          } else {
            results = results[0];
            results.isEdit = false;
          }
          // console.log("results:", results);
          this.account = results.user_account;
          this.username = results.user_name;
          this.company_id = results.company_id;
          this.user_enable = results.status;
        })
        .catch((err) => console.log(err));
      //Todo api: getUser

      // todo no user => alert Router push List
    },
    handle_api_errorcode(ErrorCode){
      // console.log("handle_api_errorcode, ErrorCode: " + ErrorCode)
      let function_name_str = ''
      let already_alert = false;
      if(this.route_name == "userAdd"){
        function_name_str = "新增"
        if (ErrorCode === "user001") {
          already_alert = true;
          alert(function_name_str + "失敗，使用者帳號已存在。錯誤代碼: user001。");
        };
      } else if(this.route_name == "userEdit"){
        function_name_str = "更新"
        if (ErrorCode === "userEdit001") {
          already_alert = true;
          alert(function_name_str + "失敗，使用者帳號不存在。錯誤代碼: userEdit001。");
        };
      }
      if(already_alert === false){
        if (ErrorCode === "user002") {
          alert(function_name_str + "失敗，密碼強度不足，密碼長度需介於 8~20，須包含大小寫字母、數字與特殊符號至少各 1。錯誤代碼: user002。"
          );
        }
        else if(ErrorCode === "Exception") {
          alert(function_name_str + "失敗，系統錯誤。錯誤代碼: Exception。");
        }
        else{
          alert(function_name_str + "失敗，未知錯誤。錯誤代碼: unknown");
        };
      };
    }
  },
};
</script>

<style lang="scss"></style>
