import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLogin: false,
    api_baseUrl: 'https://portal-backend.logondemand.com', //production use this
    // api_baseUrl: 'http://127.0.0.1:5000', //development use this
    user_id: false,
    user_name: '',
    user_account: '',
    company_id: false,
    company_name: '',
    permission: false,
  },
  mutations: {
    userLogin(state, userdata) {
      state.isLogin = userdata['flag'],
      state.user_id = userdata['user_id'],
      state.user_name = userdata['user_name'],
      state.user_account = userdata['user_account'],
      state.company_id = userdata['company_id'],
      state.permission = userdata['permission']
    },
    resetUserLogin(state){
      state.isLogin = false
      state.user_id = false,
      state.user_name = '',
      state.user_account = '',
      state.company_id = false,
      state.company_name = '',
      state.permission = false
    }
  },
  getters: {
    isLogin: state => state.isLogin,
    user_id: state => state.user_id,
    user_name: state => state.user_name,
    user_account: state => state.user_account,
    company_id: state => state.company_id,
    company_name: state => state.company_name,
    permission: state => state.permission,
    api_baseUrl: state => state.api_baseUrl,
  },
  actions: {

  },
  modules: {
  },
});
