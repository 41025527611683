<template>
  <div>
    <router-link :to="{ path: '/user/add' }">
      <el-button type="button" size="medium">新增使用者</el-button>
    </router-link>
    <h1>查詢使用者</h1>
    <UserManagementForm :companies="companies" @searchUser="searchUser" />
    <h1 v-if="isSearch">查詢結果</h1>
    <div class="results-features" id="results-features" v-show="isSearch">
      <div class="results-features-2" id="results-features-2">
        <label for="results-qty-onepage">每頁要顯示的數量:</label>
        <select
          name="results-qty-onepage"
          id="results-qty-onepage"
          v-model="perPage"
        >
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
    <div class="paginationCntr" v-show="isSearch">
      <!-- <slot name="SearchPagePagination">[Slot SearchPagePagination Default]</slot> -->
    </div>
    <el-table :data="calcUserData" style="width: 100%" v-show="isSearch" empty-text="找不到符合條件的資料">
      <el-table-column prop="user_id" label="會員編號"> </el-table-column>
      <el-table-column prop="user_account" label="帳號"> </el-table-column>
      <el-table-column prop="user_name" label="名稱"> </el-table-column>
      <el-table-column prop="company_id" label="貨主">
        <template slot-scope="scope">
          <label>{{get_company_name(scope.row.company_id)}}</label>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="狀態">
        <template slot-scope="scope">
          <label>{{ scope.row.status===true?"啟用":"停用" }}</label>
        </template>
      </el-table-column>
      <el-table-column prop="features" label="功能">
        <template slot-scope="scope">
          <div class="td__btn">
            <el-button
              @click="handleEdit(scope.row)"
              size="mini"
              >
              修改
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :currentPage="currentPage"
      :totalPage="totalPage"
      @updateCurrentPage="updateCurrentPage"
    ></Pagination>
  </div>
</template>

<script>
// @ is an alias to /src
import UserManagementForm from "@/components/Form/UserManagementForm.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import axios from "axios";
export default {
  components: {
    Pagination,
    UserManagementForm,
  },
  data() {
    return {
      isSearch: false,
      userData: [],
      companies: [],
      api_baseUrl: this.$store.state.api_baseUrl,
      currentPage: 1,
      perPage: 15,
    };
  },
  mounted() {
    this.get_companies();
  },
  computed: {
    totalPage() {
      return Math.ceil(this.userData.length / this.perPage);
    },
    calcUserData() {
      return this.userData.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
  },
  methods: {
    handleEdit(scope_row) {
      this.$router.push(`user/${scope_row.user_id}`);
    },
    updateCurrentPage(targetPage) {
      this.currentPage = targetPage;
    },
    searchUser(conditions) {
      this.isSearch = true;
      axios
        .get(this.api_baseUrl + "/User", {
          params: {
            user_name: conditions.user_name,
            user_account: conditions.user_account,
            company_id: conditions.company_id,
            user_enable: conditions.Status,
          },
        })
        .then((res) => {
          let results = res.data["Results"];
          results.forEach(function (item) {
            item.isEdit = false;
          });
          this.userData = results;
          // console.log("user_enable:", conditions.Status);
          // console.log("results:", results);
        })
        .catch((err) => console.log(err));
    },
    get_companies() {
      axios
        .get(this.api_baseUrl + "/Company")
        .then((res) => {
          this.companies = res.data;
        })
        .catch((err) => console.log(err));
    },
    get_company_name(company_id) {
      let company = this.companies.filter(
        (item) => item.company_id == company_id
      )[0];
      return company.company_name;
    },
  },
};
</script>

<style lang="scss">
.td__btn {
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
:deep .el-table__empty-block {
  display: none;
}
</style>
