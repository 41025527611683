<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">{{ $route.meta.pageName }}</a>
      <div v-if="is_mobile_mode" class="navbar-right">
        <label style="float: right;">點擊開啟功能選單-></label>
      </div>
      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <p-button class="logout" round outline block @click.native="logout">
              登出
            </p-button>
          </li>
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-panel"></i>
              <p>狀態</p>
            </a>
          </li> -->
          <!-- <drop-down class="nav-item"
                     title="5 項通知"
                     title-classes="nav-link"
                     icon="ti-bell">
            <a class="dropdown-item" href="#">通知 1</a>
            <a class="dropdown-item" href="#">通知 2</a>
            <a class="dropdown-item" href="#">通知 3</a>
            <a class="dropdown-item" href="#">通知 4</a>
            <a class="dropdown-item" href="#">其他通知</a>
          </drop-down> -->
          <!-- <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="ti-settings"></i>
              <p>
                設定
              </p>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import axios from "axios";
import LogoutNotification from "@/components/Notifications/LogoutNotification";

export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      api_baseUrl: this.$store.state.api_baseUrl,
      navbar_right_width: 80,
      is_mobile_mode: false,
    };
  },
  created() {
    window.addEventListener("resize", this.window_resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.window_resize);
  },
  methods: {
    logout() {
      this.$emit("TopNavBarLogout");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    window_resize(e){
      if(window.innerWidth<=990){
        this.is_mobile_mode=true;
      }
      else{
        this.is_mobile_mode=false;
      };
    },
  },
};
</script>
<style scoped lang="scss">
// .navbar-right{
//   float: right;
//   background-color: red;
//   width: 80%;
//   height: 25px;
// };

.navbar-right {
  @media (max-width: 990px) and (min-width: 780px) {
    width: 90%;
  };
  @media (max-width: 780px) and (min-width: 520px) {
    width: 85%;
  };
  @media (max-width: 520px) and (min-width: 390px) {
    width: 80%;
  };
  @media (max-width: 390px) and (min-width: 260px) {
    width: 70%;
  };
  @media (max-width: 260px){
    width: 60%;
  };
}
</style>
