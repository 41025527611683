<template>
  <BaseSearchForm>
    <template #SearchConditions>
      <SingleRowCondition>
        <template #Text>
          <label for="EXTERNORDERKEY">客單編號：</label>
        </template>
        <template #Content>
          <input class="form__input"
              id="EXTERNORDERKEY"
              v-model="EXTERNORDERKEY"
              name="EXTERNORDERKEY"
              type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="ORDERKEY">出貨單號：</label>
        </template>
        <template #Content>
          <input class="form__input"
                id="ORDERKEY"
                v-model="ORDERKEY"
                name="ORDERKEY"
                type="text"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="ORDERDATE">訂單日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleOrderStartDateChange"
                      :date="orderDate.startDate"
                      :max="orderDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleOrderEndDateChange"
                      :date="orderDate.endDate"
                      :min="orderDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="SHIPPINGDATE">出貨日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleShipStartDateChange"
                      :date="shipDate.startDate"
                      :max="shipDate.endDate"/>
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleShipEndDateChange"
                      :date="shipDate.endDate"
                      :min="shipDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="SPECIALDATE">指定配送日：</label>
        </template>
        <template #Content>
          <Datepicker class="form__datepicker"
                      @update="handleScheduledShipStartDateChange"
                      :date="ScheduledShipDate.startDate"
                      :max="ScheduledShipDate.endDate"
          />
          <span>~</span>
          <Datepicker class="form__datepicker"
                      @update="handleScheduledShipEndDateChange"
                      :date="ScheduledShipDate.endDate"
                      :min="ScheduledShipDate.startDate"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="C_COMPANY">訂購人：</label>
        </template>
        <template #Content>
          <input class="form__input"
                id="C_COMPANY"
                v-model="C_COMPANY"
                type="text">
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="C_CONTACT1">提貨人：</label>
        </template>
        <template #Content>
          <input class="form__input"
                id="C_CONTACT1"
                v-model="C_CONTACT1"
                type="text">
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="ORDERTYPE">訂單類別：</label>
        </template>
        <template #Content>
          <Select
            :selected_value="orderType"
            @change="handleOrderTypeChange"
            :options="orderTypeList"/>
        </template>
      </SingleRowCondition>
      <SingleRowCondition>
        <template #Text>
          <label for="C_COMPANY">訂單狀態：</label>
        </template>
        <template #Content>
          <Select
              :selected_value="orderStatus"
              @change="handleOrderStatusChange"
              :options="orderStatusList" />
        </template>
      </SingleRowCondition>
      <div class="form__row">
        <div class="form__col form__col--head">
          <button class="form__btn form__btn-primary btn_search"
                  type="button"
                  @click="transData"> 搜尋</button>
          <button class="form__btn form__btn-default btn_clear"
                  type="button"
                  @click="clearData"> 清空 </button>
        </div>
      </div>
    </template>
  </BaseSearchForm>
</template>

<script>
import SearchFormMixin from '@/mixins/SearchFormMixin.js'
import BaseSearchForm from '@/components/Form/BaseSearchForm.vue';
import SingleRowCondition from '@/components/Form/SingleRowCondition';

export default {
  name: 'ShippingForm',
  mixins: [SearchFormMixin],
  components: {
    BaseSearchForm,
    SingleRowCondition,
  },
  props: {
    orderTypeList: {
      default: function () {
        return []
      },
      type: Array
    },
    orderStatusList: {
      default: function () {
        return []
      },
      type: Array
    }
  },
  data() {
    return {
      EXTERNORDERKEY: '',
      ORDERKEY: '',
      shipDate: {
        startDate: '',
        endDate: '',
      },
      orderDate: {
        startDate: '',
        endDate: '',
      },
      ScheduledShipDate : {
        startDate: '',
        endDate: '',
      },
      C_COMPANY: '',
      C_CONTACT1: '',
      orderType: '',
      orderStatus: '',
    };
  },
  created() {
  },
  computed: {},
  mounted() {
  },
  methods: {
    transData() {
      if(
        this.EXTERNORDERKEY == '' &&
        this.ORDERKEY == '' &&
        this.shipDate.startDate == '' &&
        this.shipDate.endDate == '' &&
        this.orderDate.startDate == '' &&
        this.orderDate.endDate == '' &&
        this.ScheduledShipDate.startDate == '' &&
        this.ScheduledShipDate.endDate == '' &&
        this.C_COMPANY == '' &&
        this.C_CONTACT1 == '' &&
        this.orderType == '' &&
        this.orderStatus == ''
      ){
        alert("搜尋條件不得為空，請輸入搜尋條件後重試。");
      } else {
        this.$emit('updateConditions', {
          EXTERNORDERKEY: this.EXTERNORDERKEY,
          ORDERKEY: this.ORDERKEY,
          shipDate: this.shipDate,
          orderDate: this.orderDate,
          ScheduledShipDate: this.ScheduledShipDate,
          C_COMPANY: this.C_COMPANY,
          C_CONTACT1: this.C_CONTACT1,
          orderType: this.orderType,
          orderStatus: this.orderStatus,
        });
      };
    },
    clearData(){
      this.EXTERNORDERKEY = '';
      this.ORDERKEY= '';
      this.shipDate.startDate= '';
      this.shipDate.endDate= '';
      this.orderDate.startDate= '';
      this.orderDate.endDate= '';
      this.ScheduledShipDate.startDate= '';
      this.ScheduledShipDate.endDate= '';
      this.C_COMPANY= '';
      this.C_CONTACT1= '';
      this.orderType= '';
      this.orderStatus= '';
    },
    handleShipStartDateChange(value) {
      this.shipDate.startDate = value;
    },
    handleShipEndDateChange(value) {
      this.shipDate.endDate = value;
    },
    handleOrderStartDateChange(value) {
      this.orderDate.startDate = value;
    },
    handleOrderEndDateChange(value) {
      this.orderDate.endDate = value;
    },
    handleScheduledShipStartDateChange(value) {
      this.ScheduledShipDate.startDate = value;
    },
    handleScheduledShipEndDateChange(value) {
      this.ScheduledShipDate.endDate = value;
    },
    handleOrderTypeChange(value) {
      this.orderType = value;
    },
    handleOrderStatusChange(value) {
      this.orderStatus = value;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
