<template>
  <div>
    <section v-if="is_mobile_mode">
      <h2>請點擊右上角功能選單</h2>
      <h3>進入相應功能畫面</h3>
    </section>
    <section v-else>
      <h2>請點擊左方功能選單</h2>
      <h3>進入相應功能畫面</h3>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      is_mobile_mode: false,
    };
  },
  created() {
    window.addEventListener("resize", this.window_resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.window_resize);
  },
  methods: {
    window_resize(e){
      if(window.innerWidth<=990){
        this.is_mobile_mode=true;
      }
      else{
        this.is_mobile_mode=false;
      };
    },
  }
};
</script>

<style scoped lang="scss">
</style>
