// @ is an alias to /src
import Table from '@/components/Table/Table.vue';
import PreviewColumns from '@/components/Table/PreviewColumns.vue';
import Chooser from '@/components/Chooser/Chooser.vue';
import Pagination from '@/components/Pagination/Pagination.vue';


export default {
  components: {
    Pagination,
    Table,
    Chooser,
    PreviewColumns,
  },
  data() {
    return {
      isSearch : false,
      orderData: [],
      columns: [],
      selectColumns: [],
      pagination: {
        currentPage: 1,
        totalPage: 0,
        perPage: 15,
        totalCount: 0,
      },
      which_columns: '',
      api_baseUrl: this.$store.state.api_baseUrl,
      search_conditions: '',
    };
  },
  created() {
    this.getColumns();
  },
  methods: {
    updateSelectColumns(selectColumns) {
      // console.log(selectColumns);
      this.selectColumns = selectColumns;
      if (selectColumns.length === 0) {
        this.selectColumns = this.columns;
      }
    },
    updateCurrentPage(targetPage) {
      // console.log('Page change, current page:', targetPage, ' refresh data...');
      this.pagination.currentPage = targetPage;
      this.getData(this.search_conditions);
    },
    getColumns() {
      this.axios.get(this.api_baseUrl + '/CustomColumns', { params: { 'custom_columns_id': this.which_columns}}).then((res) => {
        this.columns = res.data;
        this.selectColumns = res.data;
        // console.log('SearchPageMixin columns', this.columns)
      })
        .catch((err) => console.log(err));
    },
    updatePerPage(qty){
      this.pagination.perPage = parseInt(qty);
      this.pagination.totalPage = Math.ceil(this.pagination.totalCount / this.pagination.perPage);
      if(this.pagination.currentPage * this.pagination.perPage > this.pagination.totalCount){
        this.pagination.currentPage = this.pagination.totalPage;
      }
      this.getData(this.search_conditions);
      // console.log('SearchPageMixin get updatePerPage:', qty)
    }
  },
};
